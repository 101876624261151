<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";
import { form, formV$ } from "~/validations/domainFormRules";
import { useDomainFormStore } from "~/store/useDomainFormStore";

const { nextTab, prevTab } = defineProps({
  nextTab: Function,
  prevTab: Function,
});

const { domainStore, authStore } = {
  domainStore: useDomainFormStore(),
  authStore: useAuthStore(),
};

const { isSubmitted, responseError } = storeToRefs(domainStore);
const { clearResponseError } = domainStore;

const { header } = storeToRefs(authStore);

const apis = new APIS();
const requestUri = `${apis.BASE_URL + apis.ADD_DOMAIN}`;
const requestHeaders = computed(() => ({ headers: header.value }));
const { sendPostRequest } = useSendRequest();

const loading = ref(false);

async function handleSubmit(isFormValid) {
  isSubmitted.value = true;
  if (isFormValid) return;

  loading.value = true;

  try {
    const requestBody = { domain_name: form.domain };
    const response = await sendPostRequest(requestUri, requestHeaders.value, requestBody);

    switch (response.status) {
      case 201:
        form.txtValue = response.data.data.txt_value;
        nextTab();
        break;
      case 226:
      case 400:
      case 406:
        responseError.value = response.data.message;
        break;
      default:
        responseError.value = "An unexpected error occurred";
    }
  } catch (error) {
    responseError.value = error.message || "Network error";
  } finally {
    loading.value = false;
  }
}
</script>
<template>
  <div class="mt-7 w-full">
    <CustomFieldText
      :Field="formV$.domain"
      :isSubmitted="isSubmitted"
      :label="'Enter your custom domain'"
      :inputId="'domain-input-field'"
      :placeholder="'example.com'"
      :extraInputClass="'w-full'"
      :responseError="responseError"
      :showResponseError="true"
      :helpText="'Please do not include \'https://\' or \'http://\' in the domain. For example: \'smartlnks.com\'.'"
      @clearResponseError="clearResponseError"
    />

    <div class="flex justify-end w-full">
      <Button
        @click="handleSubmit(formV$.$invalid)"
        class="p-button-sm !mr-2 !p-2.5 text-xs"
        label="Add Domain"
        :loading="loading"
      />
    </div>
  </div>
</template>

<style></style>
