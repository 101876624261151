<script setup>
const { activeTab } = defineProps({
  activeTab: {
    type: Number,
  },
});

const tabs = [
  { title: "Enter Domain", number: 1 },
  { title: "Verify Domain", number: 2 },
  { title: "Configure DNS", number: 3 },
];

const getClass = (tabNumber) => {
  let classes = "flex items-center whitespace-nowrap";
  if (tabNumber !== 3) {
    classes +=
      " md:w-full after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-3 xl:after:mx-5 dark:after:border-gray-700";
  } else {
    classes += " whitespace-nowrap";
  }
  if (activeTab > tabNumber - 1) {
    classes += " text-blue-600 dark:text-blue-500";
  }
  return classes;
};
</script>

<template>
  <ol
    class="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base remove-x-scroll overflow-auto"
  >
    <li v-for="tab in tabs" :key="tab.number" :class="getClass(tab.number)">
      <span
        class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500"
      >
        <SvgBlueTick v-if="activeTab > tab.number - 1" />
        <span v-else class="mr-2">{{ tab.number }}</span>
        <span class="hidden sm:inline-flex sm:ml-2"> {{ tab.title }}</span>
      </span>
    </li>
  </ol>
</template>

<style></style>
