<script setup>
import ConfettiExplosion from "vue-confetti-explosion";
import GreenTick from "~~/assets/lottie/green-tick.json";
import { form, formV$ } from "~/validations/domainFormRules";
import { Vue3Lottie } from 'vue3-lottie'

const props = defineProps({
  action: {
    type: String,
  },
});

const visitDomain = () => {
  window.open("https://" + form.domain, "_blank");
};

const closeDialog = inject("close-domain-form");
</script>

<template>
  <div class="mt-7 w-full">
    <ConfettiExplosion
      :particleCount="150"
      :duration="7000"
      :shouldDestroyAfterDone="true"
    />

    <div class="flex w-full">
      <Vue3Lottie :animationData="GreenTick" :height="300" />
    </div>

    <h2 class="text-md font-medium">
      🎉 Congratulations! Your custom domain (<span
        class="bg-blue-100 px-1.5 rounded-sm text-blue-700 font-medium"
        >{{ form.domain }}</span
      >) has been successfully {{ action === "add" ? "added" : "updated" }} and is now
      live. 🚀 <br /><br />
      You can start creating smart links using your custom domain and take advantage of
      its growth potential. Thank you for choosing our service! 💪
    </h2>

    <div class="flex justify-end w-full mt-5">
      <div>
        <Button
          class="p-button-sm !mr-3 !p-2.5 !px-5 text-xs"
          label="Close"
          id="go-back-btn-enter-domain"
          @click="closeDialog()"
        />
        <Button
          @click="visitDomain()"
          class="p-button-sm !mr-2 !p-2.5 !px-5 text-xs"
          label="Visit"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#go-back-btn-enter-domain {
  @apply !bg-gray-50 !border-gray-50 !text-black;
}
</style>
