<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";
import { useDomainFormStore } from "~/store/useDomainFormStore";
import { form, formV$ } from "~/validations/domainFormRules";
import { copyTextToClipboard } from "~/utils/copyTextToClipboard.js";

const { nextTab, prevTab } = defineProps({
  nextTab: Function,
  prevTab: Function,
});

const { domainStore, authStore } = {
  domainStore: useDomainFormStore(),
  authStore: useAuthStore(),
};

const { isSubmitted, responseError } = storeToRefs(domainStore);
const { clearResponseError } = domainStore;

const { header } = storeToRefs(authStore);

const apis = new APIS();
const requestUri = `${apis.BASE_URL + apis.VERIFY_DOMAIN}`;
const requestHeaders = computed(() => ({ headers: header.value }));
const { sendPostRequest } = useSendRequest();

const loading = ref(false);
const cooldown = ref(false);

async function handleSubmit() {
  loading.value = true;

  try {
    const requestBody = { domain_name: form.domain };
    const response = await sendPostRequest(requestUri, requestHeaders.value, requestBody);

    switch (response.status) {
      case 200:
        if (response.data?.data?.is_verified) {
          form.uniqueDNS = response.data.data.unique_dns;
          clearResponseError();
          nextTab();
        } else {
          responseError.value = response.data.details;
        }
        break;
      case 400:
      case 404:
        responseError.value = response.data?.details;
        break;
      default:
        responseError.value = response.data?.details || "An unexpected error occurred";
    }
  } catch (error) {
    responseError.value = error.message || "Network error";
  } finally {
    loading.value = false;
    cooldown.value = true;
  }
}

const onCoodownEnd = () => {
  cooldown.value = false;
};
</script>

<template>
  <div class="mt-7 w-full">
    <span class="text-md font-medium">
      To verify the ownership of
      <span class="bg-blue-100 px-1.5 rounded-sm text-blue-700">{{ form.domain }}</span
      >, please add the following TXT record to its DNS configuration:
    </span>

    <div class="bg-gray-50 p-4 rounded-md mt-4">
      <table
        class="w-full table-fixed text-sm text-left text-gray-500 dark:text-gray-400"
      >
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-4 dark:bg-[#1f1f1f] w-[15%]">Type</th>
            <th scope="col" class="py-3 px-6 w-[15%]">
              <span class="w-full flex justify-center"> Name</span>
            </th>
            <th scope="col" class="py-3 px-6 w-[55%]">
              <span class="w-full flex justify-center"> Value</span>
            </th>
            <th scope="col" class="py-3 px-6 w-[15%]">
              <span class="w-full flex justify-center"> TTL</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b last:border-b-0 dark:border-b-[#181818] border-gray-200 dark:border-gray-700"
          >
            <th
              scope="row"
              class="py-4 px-4 mx-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-[#1f1f1f] overflow-x-auto remove-scroll"
            >
              TXT
            </th>
            <td class="py-4">
              <span class="flex justify-center w-full font-medium text-gray-900"> @</span>
            </td>
            <td class="py-4">
              <span
                class="flex justify-center w-full break-all mx-2 px-6 font-medium text-gray-900"
              >
                <i
                  class="pi pi-copy pr-2 text-blue-600 cursor-pointer"
                  @click="copyTextToClipboard({ url: form.txtValue })"
                ></i>
                {{ form.txtValue }}</span
              >
            </td>
            <td class="py-4">
              <span class="flex justify-center w-full font-medium text-gray-900">
                Auto</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <small v-if="responseError" class="block my-3 text-red-500">{{
      responseError
    }}</small>
    <span class="block mt-4 text-sm">
      Warning: Please be aware that adding this TXT record will transfer ownership of the
      domain away from any other sites using it, which could result in those sites
      breaking or malfunctioning. It's important to fully understand the implications
      before proceeding with adding this record.
    </span>

    <div class="flex justify-end w-full mt-5">
      <div>
        <!-- <Button
          icon="pi pi-angle-double-left"
          class="p-button-sm !mr-3 !p-2.5 text-xs"
          label="go back"
          id="go-back-btn-enter-domain"
          @click="goBack"
        /> -->
        <Button v-if="cooldown" :disabled="true" class="p-button-sm !mr-2 !p-2.5 text-xs">
          <vue-countdown
            v-if="cooldown"
            :time="30000"
            @end="onCoodownEnd"
            v-slot="{ totalSeconds }"
            >Verify again {{ totalSeconds }} seconds later</vue-countdown
          >
        </Button>
        <Button
          v-else
          @click="handleSubmit"
          class="p-button-sm !mr-2 !p-2.5 text-xs"
          label="Verify Domain"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#go-back-btn-enter-domain {
  @apply !bg-gray-50 !border-gray-50 !text-black;
}
</style>
