<script setup>
import { form, resetForm } from "~/validations/domainFormRules";
import { useDomainFormStore } from "~/store/useDomainFormStore";
import { storeToRefs } from "pinia";

const props = defineProps({
  action: {
    type: String,
    default: "add",
  },
  data: {
    type: Object,
  },
});

const emit = defineEmits(["close"]);

const { reset, clearResponseError } = useDomainFormStore();
const activeTab = ref(0);

const nextTab = () => {
  if (activeTab.value < 5) {
    activeTab.value++;
    clearResponseError();
  }
};

const prevTab = () => {
  if (activeTab.value > 0) {
    activeTab.value--;
    clearResponseError();
  }
};

const determineActiveTab = (data) => {
  if (data.is_dns_verified && data.is_ownership_verified && data.is_ssl_certificate_given)
    return 4;
  else if (data.is_dns_verified && data.is_ownership_verified) return 3;
  else if (!data.is_ownership_verified) return 1;
  else if (!data.is_dns_verified || !data.is_ssl_certificate_given) return 2;
  return 0;
};

const handleDialogClose = () => {
  emit("close", { refresh: activeTab.value > 0 ? true : false });
};
provide("close-domain-form", handleDialogClose);

onMounted(() => {
  if (props.action === "edit" && props.data) {
    const {
      domain_name,
      txt_value,
      dns_value,
      landing_page_url,
      not_found_page_url,
    } = props.data;
    form.domain = domain_name;
    form.txtValue = txt_value;
    form.uniqueDNS = dns_value;
    form.landingPageUrl = landing_page_url;
    form.notFoundPageUrl = not_found_page_url;
    activeTab.value = determineActiveTab(props.data);
  }
});

onUnmounted(() => resetForm(), reset());

const header = computed(() =>
  props.action === "edit" && activeTab.value > 2 ? "Edit Domain" : "Add Domain"
);
</script>
<template>
  <Dialog
    :visible="true"
    :header="header"
    @update:visible="handleDialogClose()"
    modal
    id="add-domain-dialog"
  >
    <div class="my-5"></div>

    <DialogDomainFormSteps :activeTab="activeTab" />

    <DialogDomainFormEnterDomain
      v-if="activeTab === 0"
      :nextTab="nextTab"
      :prevTab="prevTab"
    />
    <DialogDomainFormVerifyDomain
      v-else-if="activeTab === 1"
      :nextTab="nextTab"
      :prevTab="prevTab"
    />
    <DialogDomainFormConfigureDns
      v-else-if="activeTab === 2"
      :nextTab="nextTab"
      :prevTab="prevTab"
    />
    <DialogDomainFormObtainSsl
      v-else-if="activeTab === 3"
      :nextTab="nextTab"
      :prevTab="prevTab"
    />
    <!-- Custom domain url page -->
    <DialogDomainFormCustomDomainPageUrl
      v-else-if="activeTab === 4"
      :nextTab="nextTab"
      :prevTab="prevTab"
    />
    <!-- domain added successfully -->
    <DialogDomainFormCongratulation v-else-if="activeTab === 5" :action="action" />
  </Dialog>
</template>

<style lang="postcss">
#add-domain-dialog {
  @apply lg:w-[30%] w-[70%];
}
</style>
