import {
  maxLength,
  required,
  url,
  minLength,
  minValue,
  numeric,
  helpers,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { domainRegex, domainOnlyRegex } from "~/utils/regex";

// Common Validators
const requiredMsg = (msg) => helpers.withMessage(msg, required);
const minLengthMsg = (msg, len) => helpers.withMessage(msg, minLength(len));
const maxLengthMsg = (msg, len) => helpers.withMessage(msg, maxLength(len));
const validUrl = helpers.withMessage("The entered value is not a valid URL address", url);
const isDomain = (str) => domainOnlyRegex.test(str);

const defaultFormValues = () => ({
  id: 0,
  domain: "",
  txtValue: "",
  uniqueDNS: "",
  landingPageUrl: "",
  notFoundPageUrl: "",
});

export const form = reactive(defaultFormValues());

export const formRules = {
  domain: {
    required: requiredMsg("Domain is required"),
    is_valid_domain: helpers.withMessage(
      "We do not allow subdomains to be added, only root domains are allowed",
      (value) => {
        return isDomain(value);
      }
    ),
  },
  landingPageUrl: {
    url: validUrl,
  },
  notFoundPageUrl: {
    url: validUrl,
  },
};

export const formV$ = useVuelidate(formRules, form);

export const resetForm = () => {
  const defaults = defaultFormValues();
  for (const key in form) {
    if (defaults.hasOwnProperty(key)) {
      form[key] = defaults[key];
    }
  }
};
