<script setup>
import { notify } from "notiwind";
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";
import { useDomainFormStore } from "~/store/useDomainFormStore";
import { form, formV$ } from "~/validations/domainFormRules";
import { copyTextToClipboard } from "~/utils/copyTextToClipboard.js";

const { nextTab, prevTab } = defineProps({
  nextTab: Function,
  prevTab: Function,
});

const { domainStore, authStore } = {
  domainStore: useDomainFormStore(),
  authStore: useAuthStore(),
};

const { isSubmitted, responseError } = storeToRefs(domainStore);
const { clearResponseError } = domainStore;

const { header } = storeToRefs(authStore);

const apis = new APIS();
const requestUri = `${apis.BASE_URL + apis.VERIFY_DNS}`;
const requestHeaders = computed(() => ({ headers: header.value }));
const { sendPostRequest } = useSendRequest();

const loading = ref(false);
const cooldown = ref(false);

async function handleSubmit() {
  loading.value = true;

  try {
    const requestBody = { domain_name: form.domain };
    const response = await sendPostRequest(requestUri, requestHeaders.value, requestBody);
    switch (response.status) {
      case 200:
        if (response.data?.data?.is_dns_conf) {
          clearResponseError();
          nextTab();
        } else {
          responseError.value = response.data?.details;
        }
        break;
      case 400:
      case 404:
        responseError.value = response.data.details;
        break;
      default:
        responseError.value = response.data.details || "An unexpected error occurred";
    }
  } catch (error) {
    responseError.value = error.message || "Network error";
  } finally {
    loading.value = false;
    cooldown.value = true;
  }
}

function onCoodownEnd() {
  cooldown.value = false;
}
</script>

<template>
  <div class="mt-7 w-full">
    <span class="text-md font-medium">
      In order to configure your apex domain (
      <span class="bg-blue-100 px-1.5 rounded-sm text-blue-700">{{ form.domain }}</span>
      ), you will need to set the following A record on your DNS provider:
    </span>

    <div class="bg-gray-50 p-4 rounded-md mt-4">
      <table
        class="w-full table-fixed text-sm text-left text-gray-500 dark:text-gray-400"
      >
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-4 dark:bg-[#1f1f1f] w-[15%]">Type</th>
            <th scope="col" class="py-3 px-6 w-[15%]">
              <span class="w-full flex justify-center"> Name</span>
            </th>
            <th scope="col" class="py-3 px-6 w-[55%]">
              <span class="w-full flex justify-center"> Value</span>
            </th>
            <th scope="col" class="py-3 px-6 w-[15%]">
              <span class="w-full flex justify-center"> TTL</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-b last:border-b-0 dark:border-b-[#181818] border-gray-200 dark:border-gray-700"
          >
            <th
              scope="row"
              class="py-4 px-4 mx-2 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-[#1f1f1f] overflow-x-auto remove-scroll"
            >
              A
            </th>
            <td class="py-4">
              <span class="flex justify-center w-full font-medium text-gray-900"> @</span>
            </td>
            <td class="py-4">
              <span
                class="flex justify-center w-full break-all mx-2 px-6 font-medium text-gray-900"
              >
                <i
                  class="pi pi-copy pr-2 text-blue-600 cursor-pointer"
                  @click="copyTextToClipboard({ url: form.uniqueDNS })"
                ></i>
                {{ form.uniqueDNS }}</span
              >
            </td>
            <td class="py-4">
              <span class="flex justify-center w-full font-medium text-gray-900">
                Auto</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <small v-if="responseError" class="block my-3 text-red-500">{{
      responseError
    }}</small>
    <span class="block mt-4 text-sm">
      NOTE: When updating DNS records, keep in mind that it may take anywhere from 1 to 48
      hours for changes to propagate throughout the internet. So be patient as it may take
      some time for the changes to take effect.
      <!-- Also If
      86400 seconds is not available for TTL, set the highest value possible. -->
    </span>

    <div class="flex justify-end w-full mt-5">
      <div>
        <Button
          @click="prevTab()"
          icon="pi pi-angle-double-left"
          class="p-button-sm !mr-3 !p-2.5 text-xs"
          label="go back"
          id="go-back-btn-verify-domain"
        />
        <Button v-if="cooldown" :disabled="true" class="p-button-sm !mr-2 !p-2.5 text-xs">
          <vue-countdown
            v-if="cooldown"
            :time="60000"
            @end="onCoodownEnd"
            v-slot="{ totalSeconds }"
            >Verify again {{ totalSeconds }} seconds later</vue-countdown
          >
        </Button>
        <Button
          v-else
          @click="handleSubmit"
          class="p-button-sm !mr-2 !p-2.5 text-xs"
          label="Verify DNS"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#go-back-btn-verify-domain {
  @apply !bg-gray-50 !border-gray-50 !text-black;
}
</style>
