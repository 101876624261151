<script setup>
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";
import { useDomainFormStore } from "~/store/useDomainFormStore";
import { form, formV$ } from "~/validations/domainFormRules";
import { notifyError } from "~/utils/notifications";

const { nextTab, prevTab } = defineProps({
  nextTab: Function,
  prevTab: Function,
});

const { domainStore, authStore } = {
  domainStore: useDomainFormStore(),
  authStore: useAuthStore(),
};

const { isSubmitted, responseError } = storeToRefs(domainStore);
const { clearResponseError } = domainStore;

const { header } = storeToRefs(authStore);

const apis = new APIS();
const requestUri = `${apis.BASE_URL + apis.UPDATE_CUSTOM_DOMAIN_PAGE_URL}`;
const requestHeaders = computed(() => ({ headers: header.value }));
const { sendPostRequest } = useSendRequest();

const loading = ref(false);

const closeDialog = inject("close-domain-form");

async function onSubmit(isFormValid) {
  isSubmitted.value = true;
  if (isFormValid) return;

  loading.value = true;

  try {
    const requestBody = {
      domain_name: form.domain,
      landing_page_url: form.landingPageUrl,
      not_found_page_url: form.notFoundPageUrl,
    };
    const response = await sendPostRequest(requestUri, requestHeaders.value, requestBody);
    switch (response.status) {
      case 200:
        nextTab();
        break;
      case 400:
      case 404:
        responseError.value = response.data.details;
        break;
      case 406:
        responseError.value = response.data;
        break;
      default:
        responseError.value = response.data.details || "An unexpected error occurred";
    }
  } catch (error) {
    responseError.value = error.message || "Network error";
  } finally {
    loading.value = false;
  }
}

const landingPageError = computed(() => {
  return responseError.value?.details?.form_key === "landingPageUrl"
    ? responseError.value?.message
    : null;
});

const notFoundPageError = computed(() => {
  return responseError.value?.details?.form_key === "notFoundPageUrl"
    ? responseError.value?.message
    : null;
});

const globalError = computed(() => {
  return responseError.value?.details?.form_key === null
    ? responseError.value?.message
    : null;
});

watch(globalError.value, () => {
  closeDialog();
  notifyError({ title: globalError });
});

</script>
<template>
  <div class="mt-7 w-full">
    <h4 class="text-lg font-semibold">Custom Landing Page</h4>
    <span class="text-sm"
      >Please enter the URL of the landing page that you want visitors to be automatically
      redirected to when they arrive at
      <span class="bg-blue-100 px-1.5 rounded-sm text-blue-700 font-medium">{{
        form.domain
      }}</span
      >. We'll configure the necessary settings to ensure a seamless redirect
      experience.</span
    >

    <div class="mt-5">
      <CustomFieldText
        :Field="formV$.landingPageUrl"
        :isSubmitted="isSubmitted"
        :label="'Enter custom landing page URL'"
        :inputId="'domain-landing-page-input-field'"
        :placeholder="'https://smartlnks.com'"
        :extraInputClass="'w-full'"
        :responseError="landingPageError"
        :showResponseError="true"
        :helpText="'Please include \'https://\' or \'http://\' in the link. For example: \'https://smartlnks.com\'.'"
        @clearResponseError="clearResponseError"
      />
    </div>
  </div>

  <div class="mt-7 w-full">
    <h4 class="text-lg font-semibold">Custom 404 Page</h4>
    <!-- <span class="text-sm"
      >Please enter the URL of the landing page that you want visitors to be
      automatically redirected to when they arrive at
      <span class="bg-blue-100 px-1.5 rounded-sm text-blue-700 font-medium">{{
        form.domain
      }}</span
      >. We'll configure the necessary settings to ensure a seamless redirect
      experience.</span
    > -->

    <div class="mt-5">
      <CustomFieldText
        :Field="formV$.notFoundPageUrl"
        :isSubmitted="isSubmitted"
        :label="'Enter custom 404 page URL'"
        :inputId="'custom-404-page-input-field'"
        :placeholder="'https://smartlnks.com'"
        :extraInputClass="'w-full'"
        :responseError="notFoundPageError"
        :showResponseError="true"
        :helpText="'Please include \'https://\' or \'http://\' in the link. For example: \'https://smartlnks.com\'.'"
        @clearResponseError="clearResponseError"
      />
    </div>

    <div class="flex justify-end w-full mt-5">
      <div>
        <Button
          class="p-button-sm !mr-3 !p-2.5 !px-5 text-xs"
          label="Skip"
          id="go-back-btn-enter-domain"
          @click="nextTab()"
        />
        <Button
          @click="onSubmit(formV$.$invalid)"
          class="p-button-sm !mr-2 !p-2.5 !px-5 text-xs"
          label="Save"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#go-back-btn-enter-domain {
  @apply !bg-gray-50 !border-gray-50 !text-black;
}
</style>
