<script setup>
import SSLCertificateJSON from "~~/assets/lottie/ssl-certificate.json";
import { useAuthStore } from "~/store/auth.js";
import { storeToRefs } from "pinia";
import { useDomainFormStore } from "~/store/useDomainFormStore";
import { form, formV$ } from "~/validations/domainFormRules";
import { Vue3Lottie } from 'vue3-lottie'

const { nextTab, prevTab } = defineProps({
  nextTab: Function,
  prevTab: Function,
});

const { domainStore, authStore } = {
  domainStore: useDomainFormStore(),
  authStore: useAuthStore(),
};

const { isSubmitted, responseError } = storeToRefs(domainStore);
const { clearResponseError } = domainStore;

const { header } = storeToRefs(authStore);

const apis = new APIS();
const requestUri = `${apis.BASE_URL + apis.OBTAIN_SSL_CERTIFICATE}`;
const requestHeaders = computed(() => ({ headers: header.value }));
const { sendPostRequest } = useSendRequest();

const loading = ref(true);
const cooldown = ref(false);
const isRetrying = ref(false);
const showRetryBtn = ref(false);

async function obtainSSL() {
  loading.value = true;

  try {
    const requestBody = { domain_name: form.domain };
    const response = await sendPostRequest(requestUri, requestHeaders.value, requestBody);

    switch (response.status) {
      case 100:
      case 201:
        clearResponseError();
        nextTab();
        break;
      case 400:
      case 404:
        responseError.value = response.data.details;
        showRetryBtn.value = true;
        break;
      default:
        responseError.value = response.data.details || "An unexpected error occurred";
    }
  } catch (error) {
    responseError.value = error.message || "Network error";
  } finally {
    loading.value = false;
  }
}

function retrySSL() {
  isRetrying.value = true;
  obtainSSL();
  isRetrying.value = false;
  cooldown.value = true;
}

function onCoodownEnd() {
  cooldown.value = false;
}

onMounted(() => {
  obtainSSL();
});
</script>

<template>
  <!-- loader -->
  <div class="loading-overlay">
    <LoadersRingLoader />
  </div>

  <div class="mt-7 w-full">
    <Vue3Lottie :animationData="SSLCertificateJSON" :height="200" />

    <span
      v-if="!responseError && loading"
      class="text-md font-medium flex justify-center my-10"
    >
      Obtaining SSL certificate for your apex domain (<span
        class="bg-blue-100 px-1.5 rounded-sm text-blue-700"
        >{{ form.domain }}</span
      >).</span
    >

    <small v-if="responseError" class="block my-3 text-red-500">{{
      responseError
    }}</small>
    <span class="block mt-4 text-sm">
      NOTE: Getting an SSL certificate may take a few minutes or a few hours, and it might
      take some time for your website to show the changes. So, please be patient and don't
      worry.
    </span>

    <div class="flex justify-end w-full mt-5">
      <div>
        <Button
          @click="prevTab()"
          icon="pi pi-angle-double-left"
          class="p-button-sm !mr-3 !p-2.5 text-xs"
          label="go back"
          id="go-back-btn-verify-domain"
        />
        <Button
          v-if="cooldown && showRetryBtn"
          :disabled="true"
          class="p-button-sm !mr-2 !p-2.5 text-xs"
        >
          <vue-countdown
            v-if="cooldown"
            :time="120000"
            @end="onCoodownEnd"
            v-slot="{ totalSeconds }"
            >Retry again {{ totalSeconds }} seconds later</vue-countdown
          >
        </Button>
        <Button
          v-else-if="showRetryBtn"
          @click="retrySSL()"
          class="p-button-sm !mr-2 !p-2.5 text-xs"
          label="Retry"
          :loading="isRetrying"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#go-back-btn-verify-domain {
  @apply !bg-gray-50 !border-gray-50 !text-black;
}
</style>
